import { useCallback } from 'react';

import { UserInfo } from '@sbiz/business';
import { Json } from '@sbiz/util-mongodb';

import { CompanyInfo, RegisterFormData } from '../types';
import { useApi } from './useApi';

export function useAuthApi() {
  const { get, post } = useApi('auth');

  const initPasswordReset = useCallback(
    ({ email }: { email: string }, options?: { isKeycloak?: boolean }) =>
      post(options?.isKeycloak ? 'forgot/keycloak' : 'forgot', {
        captcha: { action: 'forgot_password' },
        data: { email },
        isPublic: true,
      }),
    [post],
  );

  const login = useCallback(
    (data: { email: string; password: string }) =>
      post<{ accessToken: string; user: Json<UserInfo> }>('login', {
        captcha: { action: 'login' },
        data,
        isPublic: true,
      }),
    [post],
  );

  const me = useCallback(() => get<Json<UserInfo>>('me'), [get]);

  const register = useCallback(
    (data: RegisterFormData & { companyUid?: string }) =>
      post('register', { captcha: { action: 'register' }, data, isPublic: true }),
    [post],
  );

  const resetPassword = useCallback(
    (data: { password: string; token: string }) =>
      post('reset', { captcha: { action: 'reset_password' }, data, isPublic: true }),
    [post],
  );

  const siteDataClearance = useCallback(
    (data: { siteDataClearedAt?: Date }) =>
      post<{ isRequired: boolean }>('site-data-clearance', { data, isPublic: true }),
    [post],
  );

  const verify = useCallback(
    (companyUid: string) =>
      get<CompanyInfo>('verify', { captcha: { action: 'verify_company_uid' }, params: { companyUid }, isPublic: true }),
    [get],
  );

  return { initPasswordReset, login, me, register, resetPassword, siteDataClearance, verify };
}
