import { AxiosRequestConfig } from 'axios';
import { BareFetcher, SWRConfiguration } from 'swr';

import { EmptyObject } from '@sbiz/common';
import { ApiError, StorageType } from '@sbiz/util-browser';

import { ResourceType } from './resources';

export const SAFE_ENV_LEVEL = 1;

export type ApiCacheOptions<T = unknown> = {
  isPublic?: boolean;
  params?: AxiosRequestConfig['params'];
  storage?: boolean | ApiCacheStorageOptions;
} & Partial<SWRConfiguration<T, ApiError, BareFetcher<T>>>;

export type ApiCacheStorageOptions = { expiresIn?: number; type?: StorageType };

export const API_ENVS = [
  { name: 'local', level: 0 },
  { name: 'dev', level: SAFE_ENV_LEVEL },
  { name: 'feature', level: SAFE_ENV_LEVEL },
  { name: 'stg', level: 2 },
  { name: 'prod', level: 3 },
] as const;
export type ApiEnv = (typeof API_ENVS)[number]['name'];

export type ApiFetcherOptions = { clear?: ClearCacheResourceTypeParam; clearOptions?: ClearCacheOptions };

export type ApiRequestOptions = {
  captcha?: { action?: string };
  fetcher?: ApiFetcherOptions;
  isPublic?: boolean;
  path?: string;
} & Omit<AxiosRequestConfig, 'url'>;
export type ApiRequestAliasOptions = Omit<ApiRequestOptions, 'method'>;

export const API_URLS_CONFIG_KEYS = ['domain', 'path', 'protocol', 'subdomain'] as const;
export type ApiUrlConfigKey = (typeof API_URLS_CONFIG_KEYS)[number];
export type ApiUrlConfig = Record<ApiUrlConfigKey, string>;

export type ClearCacheOptions = { isRevalidationDisabled?: boolean; omit?: string[]; path?: string };
export type ClearCacheResourceTypeParam = ResourceType | ResourceType[];

export type CompanyInfo = { name: string; postalAddress: string; uid: string };

export const DEFAULT_CSV_DELIMITER = ',';
export const CSV_DELIMITERS = [DEFAULT_CSV_DELIMITER, ';'] as const;

export type Csv = { file: File; preview: CsvPreview };
export type CsvDelimiter = (typeof CSV_DELIMITERS)[number];
type CsvInvalidCells = (string | null)[];
export type CsvFileUploadFormData<T extends object = EmptyObject> = { delimiter: CsvDelimiter } & T;
export type CsvPreview = { delimiter: CsvDelimiter } & CsvPreviewApiResponse;
export type CsvPreviewFetcher = (file: File, delimiter: CsvDelimiter) => Promise<CsvPreviewApiResponse>;
export type CsvPreviewApiResponse = { invalidRows: CsvInvalidCells[]; rows: CsvRow[] };
export type CsvRow = string[];
export type CsvUploadResponse = { validRows: CsvRow[]; invalidRows: InvalidCsvRow[] };

export type InvalidCsvRow = { row: CsvRow } & (
  | { error: string; invalidCells?: undefined }
  | { error?: undefined; invalidCells: CsvInvalidCells }
);

export type QueryValues<T> = { [P in keyof T]?: T[P] | string | null };

type RegisterField =
  | 'companyEmail'
  | 'companyName'
  | 'companyPhoneNumber'
  | 'companyPostalAddress'
  | 'companyVatNumber'
  | 'lang'
  | 'managerEmail'
  | 'managerFirstname'
  | 'managerLastname';
export type RegisterFormData = Record<RegisterField, string>;

export type SafeApiResponse<T> = { data: T; error?: never } | { data?: never; error: ApiError };
