import { useCallback } from 'react';

import { sleep } from '@sbiz/util-common';
import { getJwtExp } from '@sbiz/util-jwt';

import { STORAGE_KEYS } from '../common';
import { getFreshTokens, getSession } from '../common/auth';
import { useStorageItem } from './useStorageItem';

type Tokens = { accessToken: string; idToken: string; refreshToken: string };

type TokensCache = { status: 'idle'; value?: { expiresAt: number } & Tokens } | { status: 'error' | 'pending' };

export function useGetFreshTokens() {
  const { getValue: getCache, setValue: setCache } = useStorageItem<TokensCache>(STORAGE_KEYS.tokens);

  return useCallback(
    async function fetchTokens(depth = 0) {
      const cache = getCache();

      try {
        if (cache?.status === 'pending') {
          if (depth >= 30) {
            throw new Error();
          }

          await sleep(50);
          return fetchTokens(depth + 1);
        }

        if (cache?.status === 'idle' && (!cache?.value || cache.value.expiresAt > Date.now())) {
          return cache.value;
        }

        setCache({ status: 'pending' });
        const session = await getSession();
        const userId = session?.user?.id;

        const newCache: TokensCache = { status: 'idle' };

        if (userId) {
          const tokens = await getFreshTokens(userId);

          if (tokens) {
            const exp = getJwtExp(tokens.accessToken) as number;
            newCache.value = { ...tokens, expiresAt: (exp - 20) * 1_000 };
          }
        }

        setCache(newCache);

        return newCache.value;
      } catch {
        setCache({ status: 'error' });
      }
    },
    [getCache, setCache],
  );
}
