import { PermissionsScope } from '@sbiz/business';

import { AuthUser } from './types';

export function getCompanyId(authUser: AuthUser) {
  const { companyId, targetCompanyId } = authUser;

  if (isCompaniesAdministrator(authUser)) {
    return targetCompanyId;
  }

  return companyId;
}

export function isCompaniesAdministrator({ permissions }: AuthUser) {
  return Boolean(permissions?.companySelection?.read);
}

export function isEditor(user: AuthUser, scope: PermissionsScope) {
  return Boolean(user?.permissions?.[scope]?.write);
}
