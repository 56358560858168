import * as jwt from 'jsonwebtoken';

export function decodeJwt<T extends Partial<jwt.JwtPayload>>(token: string) {
  const payload = jwt.decode(token, { json: true });

  if (!payload) {
    throw new Error('The jwt could not be decoded');
  }

  return payload as T;
}

export function getJwtExp(token: string) {
  const payload = decodeJwt(token);
  return payload.exp;
}

export function getJwtIat(token: string) {
  const payload = decodeJwt(token);
  return payload.iat;
}

export function getJwtExpiryDate(token: string) {
  const exp = getJwtExp(token);
  return typeof exp === 'number' ? new Date(exp * 1_000) : null;
}

export function getJwtAge(token: string, unit?: 'ms' | 's' | 'min') {
  const iat = getJwtIat(token);

  const ageMs = typeof iat === 'number' ? Date.now() - iat * 1_000 : Infinity;

  if (unit === 's') {
    return ageMs / 1_000;
  }

  if (unit === 'min') {
    return ageMs / 60_000;
  }

  return ageMs;
}

export function getJwtIssuanceDate(token: string) {
  const iat = getJwtIat(token);
  return typeof iat === 'number' ? new Date(iat * 1_000) : null;
}

export function isJwtExpired(token: string, refDate?: Date) {
  const exp = getJwtExp(token);
  const timestamp = refDate?.valueOf() ?? Date.now();
  return typeof exp === 'number' && exp <= timestamp / 1_000;
}
