import { useCallback, useMemo } from 'react';

import { useConfigContext } from '../../../contexts/ConfigContext';
import { useAuthUserStorageItem } from '../../../hooks/useAuthUserStorageItem';
import { useStorageItem } from '../../../hooks/useStorageItem';
import { STORAGE_KEYS } from '../../constants';
import { API_ENVS, SAFE_ENV_LEVEL } from '../types';
import { getEnv, getFeatureApiUrl, isApiUrlEditor, isValidApiUrl } from '../utils';

export function useApiConfig() {
  const {
    api: {
      urls: { external: url },
    },
    app: { branch },
  } = useConfigContext();

  const { getValue: getAuthUser } = useAuthUserStorageItem();
  const { getValue: getUrlOverride } = useStorageItem<string | null>(STORAGE_KEYS.apiUrl);

  const envs = useMemo(() => {
    const initialEnv = url ? getEnv(url)?.level : 0;
    return API_ENVS.filter(({ level }) => level <= Math.max(SAFE_ENV_LEVEL, initialEnv ?? 0)).map(({ name }) => name);
  }, [url]);

  const featureApiUrl = useMemo(() => {
    if (branch) {
      return getFeatureApiUrl(branch);
    }
  }, [branch]);

  const getBaseURL = useCallback(() => {
    const user = getAuthUser();

    if (user) {
      const urlOverride = getUrlOverride();

      if (urlOverride && isValidApiUrl(urlOverride, envs) && (urlOverride === featureApiUrl || isApiUrlEditor(user))) {
        return urlOverride;
      }
    }

    return url;
  }, [envs, featureApiUrl, getAuthUser, getUrlOverride, url]);

  const getCurrentEnv = useCallback(() => {
    const baseURL = getBaseURL();
    if (baseURL) {
      return getEnv(baseURL)?.name;
    }
  }, [getBaseURL]);

  return { envs, featureApiUrl, getBaseURL, getCurrentEnv };
}
